import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {ResponseLogThread} from "../models/interfaces/ResponseLogThread";
import store from "../store";

/**
 * @summary 対応ログ情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "responseLogThread", namespaced: true})
export class ResponseLogThreadStore extends VuexModule {
    // #region private fields
    private _responseLogsThreads: ResponseLogThread[] = [];
    // #endregion

    // #region public getters
    /**
     * @summary 対応ログリスト
     */
    public get responseLogThreads(): ResponseLogThread[] {
        return this._responseLogsThreads;
    }
    // #endregion

    // #region public mutations
    /**
     * @summary 対応ログリストをセットします.
     * @param responseLogs 対応ログリスト
     */
    @Mutation
    public setResponseLogThreads(responseLogs: ResponseLogThread[]): void {
        this._responseLogsThreads = responseLogs;
    }
    // #endregion

    // #region actions
    /**
     * @summary 対応ログ情報をサーバーから取り込みます.
     */
    @Action
    public async fetchResponseLogThreads(responseLogId: number): Promise<void> {
        try {
            this.setResponseLogThreads([]);
            const response = await axios.get<ResponseLogThread[]>("api/response_log_thread/" + responseLogId);
            this.setResponseLogThreads(response.data);
        }
        catch {
            console.error("対応ログスレッドの取り込みに失敗しました.");
        }
    }

    /**
     * @summary 対応ログ情報を追加します.
     * @param newResponseLog 追加する対応ログ情報
     */
    @Action
    public async addResponseLogThread(responseLogThread: ResponseLogThread): Promise<boolean> {
        try {
            const response = await axios.post("api/response_log_thread", responseLogThread);
            this._responseLogsThreads.push(response.data);
            return true;
        }
        catch (ex) {
            console.error("対応ログスレッドの追加に失敗しました。", ex);
            return false;
        }
    }

    /**
     * @summary 対応ログスレッド情報を保存します.
     * @param task 保存するタスク情報
     */
    @Action
    public async editTaskThreadText(data: ResponseLogThread): Promise<boolean> {
        try {
            const response = await axios.put("api/response_log_thread/" + data.responseLogThreadId, data);
            return true;
        }
        catch {
            console.error("タスクの保存に失敗しました.");
            return false;
        }
    }

    /**
     * @summary 対応ログスレッドを削除します.
     * @param task 削除するタスク情報
     */
    @Action
    public async delete(threadId: number): Promise<boolean> {
        const response = await axios.delete("api/response_log_thread/" + threadId);
        if (response.data && response.data.result) {
            return true;
        }
        else {
            return false;
        }
    }
    // #endregion
}

export const ResponseLogStoreModule = getModule(ResponseLogThreadStore);
