import {ResponseLog} from "./interfaces/ResponseLog";

/**
 * @summary 対応ログ情報を提供します.
 */
export class ResponseLogModel implements ResponseLog {
    responseLogId = 0;
    sheetId = 0;
    nextResponseLogId = 0;
    responseDate = "";
    responseTimeRange = "";
    systemId = 0;
    responseLogStatusId = 0;
    responseType = 0;
    responsePersonId = 0;
    reporter = "";
    target = "";
    reportPerson = "";
    detailContent = "";
    responseContent = "";
    description = "";
    troubleCause = "";
    troubleMeasures = "";
    troubleCustomerReport = "";
    troubleResponsePersonId = 0;
    troubleDescription = "";
    troubleSurveyMinutes = 0;
    troubleImpactMinutes = 0;
    troubleLevel = 0;
    threadCount = 0;

    /**
     * @summary コンストラクタ
     * @param responseLog 対応ログデータ
     */
    public constructor(responseLog?: Partial<ResponseLog>) {
        // Object.assignだとクラスを使うには実装を知る必要があるのでなんだか気持ち悪いんだけど、TSではありふれた書き方というか広く認められてるものなのだろうか
        if (responseLog) {
            Object.assign(this, responseLog);
        }
    }
}
