



















































































































































import {Vue, Component, Watch} from "vue-property-decorator";
import {ResponseLogStoreModule, ResponseLogThreadStore} from "@/stores/ResponseLogThreadStore";
import {ResponseLogThread} from "@/models/interfaces/ResponseLogThread";
import {ResponseLog} from "@/models/interfaces/ResponseLog";
import {ResponseLogModel} from "../../models/ResponseLogModel";
import {AuthStore, AuthStoreModule} from "../../stores/AuthStore";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import EditThreadDialog from "../Organisms/EditThreadDialog.vue";
import NotifyDialog from "@/components/Atoms/NotifyDialog.vue";
import ConfirmDialog from "@/components/Atoms/ConfirmDialog.vue";

/**
 * @summary 対応ログに紐づくスレッドを表示するためのコンポーネントを提供します.
 */
@Component({
    components: {
        EditThreadDialog,
        NotifyDialog,
        ConfirmDialog
    }
})
export default class ResponseLogThreadView extends Vue {
    // #region private fields
    private isOpend = false;
    private resolve?: (value?: void | PromiseLike<void> | undefined) => void = undefined;
    private responseLog?: ResponseLog = new ResponseLogModel();
    private isLoading = false;
    private message = "";
    // #endregion

    // #region private getters
    /**
     * @summary 対応ログスレッドストア
     */
    private get responseLogThreadStore(): ResponseLogThreadStore {
        return ResponseLogStoreModule;
    }

    /**
     * @summary マスタ情報ストア
     */
    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    /**
     * @summary 認証情報ストア
     */
    private get authStore(): AuthStore {
        return AuthStoreModule;
    }
    // #endregion

    // #region public methods
    /**
     * @summary 対応ログスレッドビューを表示します.
     */
    public showAsync(responseLog: ResponseLog): Promise<void> {
        this.isOpend = true;
        this.responseLog = responseLog;
        this.initData();
        return new Promise<void>(resolve => this.resolve);
    }
    // #endregion

    // #region private methods
    /**
     * @summary サイドバーが変化したとき.
     * @param isOpend 開いたか閉じたか
     */
    @Watch("isOpend")
    private onIsOpendChanged(isOpend: boolean): void {
        if (!isOpend && this.resolve) {
            this.resolve();
        }
    }

    /**
     * @summary 閉じる
     */
    public close() {
        this.isOpend = false;
    }

    /**
     * @summary 最下部までスクロールします.
     */
    private scrollBottom(): void {
        this.$nextTick(() => {
            const panel = this.$refs.scrollPanel as HTMLDialogElement;
            if (panel) {
                panel.scrollTop = panel.scrollHeight;
            }
        });
    }

    /**
     * @summary データを初期化します.
     */
    private async initData(): Promise<void> {
        this.isLoading = true;
        if (this.responseLog) {
            await this.responseLogThreadStore.fetchResponseLogThreads(this.responseLog.responseLogId);
        }
        this.scrollBottom();
        this.isLoading = false;
    }

    /**
     * @summary 対応ログスレッドを追加します.
     */
    private async addResponseLogThread(): Promise<void> {
        if (!this.responseLog) {
            return;
        }
        const isSuccess = await this.responseLogThreadStore.addResponseLogThread({
            personId: this.authStore.memberId,
            text: this.message,
            responseLogThreadId: 0,
            responseLogId: this.responseLog.responseLogId,
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString()
        });
        this.responseLog.threadCount++;
        this.message = "";
        this.scrollBottom();
    }
    // #endregion

    private openEditThreadDialog(threadId: number, text: string) {
        const dialog = this.$refs.editThreadDialog as EditThreadDialog;
        if (!dialog) {
            return;
        }

        dialog.show(threadId, text);
    }

    private async editThreadText(threadId: number, text: string) {
        const notify = this.$refs.NotifyDialog as NotifyDialog;

        const data: ResponseLogThread = {responseLogThreadId: threadId, text, personId: 0, responseLogId: 0, createdAt: "", updatedAt: ""};
        const result = await this.responseLogThreadStore.editTaskThreadText(data);
        if (result) {
            if (notify) {
                await notify.showAsync("Info", "スレッドを編集しました", "閉じる");
            }
            else {
                alert("スレッドを編集しました");
            }

            const dialog = this.$refs.editThreadDialog as EditThreadDialog;
            if (dialog) {
                dialog.close();
            }

            this.isLoading = true;
            if (this.responseLog) {
                await this.responseLogThreadStore.fetchResponseLogThreads(this.responseLog.responseLogId);
            }
            this.isLoading = false;
        }
        else {
            if (notify) {
                await notify.showAsync("Info", "編集に失敗しました", "閉じる");
            }
            else {
                alert("編集に失敗しました");
            }
        }
    }

    private async openDeleteDialog(threadId: number) {
        const dialog = this.$refs.ConfirmDialog as ConfirmDialog;
        if (!dialog) {
            return;
        }

        const confirmResult = await dialog.showAsync(
            "確認",
            "本当に削除しますか？\nこの操作は取り消せません",
            "はい、削除します",
            "いいえ、キャンセルします"
        );

        if (!confirmResult) {
            return;
        }

        const notify = this.$refs.NotifyDialog as NotifyDialog;

        const result = await this.responseLogThreadStore.delete(threadId);
        if (result) {
            if (notify) {
                await notify.showAsync("Info", "削除しました", "閉じる");
            }
            else {
                alert("削除しました");
            }

            this.isLoading = true;
            if (this.responseLog) {
                await this.responseLogThreadStore.fetchResponseLogThreads(this.responseLog.responseLogId);
                this.responseLog.threadCount--;
            }
            this.isLoading = false;
        }
    }
}
